<template>
    <div>
        <app-paginated-table :fields="getFields" :items="getItems" :current-page="current_page" :total-pages="total_pages" :total="total_records" :loading="loading" @onPageChange="onPageChange" @onRowClicked="viewInvoiceDetails" :mobileHeaders="['attachment','invoiceNo','amount','quickbooks','actions']" @onLoadMore="loadMoreItems" :place-right="3">
            <template #header>
                <b-tabs @input="onTabChange">
                    <b-tab title="Recieved" v-if="getUserRole()=='client'" value="recieved" active></b-tab>
                    <b-tab v-if="getUserRole()!='client'" title="Sent" value="sent"></b-tab>
                    <b-tab v-if="getQuickbook() && getUserRole()!='client'"  title="Quickbooks" value="quickbooks"></b-tab>
                </b-tabs>   
            </template>
            <template #filters>
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-12">
                        <app-text-input v-model="filters.search" placeholder="Search" @input="getInvoices()"></app-text-input>
                    </div>
                    <!-- <div class="col-4">
                        <app-select-box v-model="filters.status" :options="options" @onChange="getInvoices()"></app-select-box>
                    </div> -->
                </div>
            </template>
            <template #head(actions)>
                {{ '' }}
            </template>
            <template #head(quickbooks)>
                <img :src="require(`@/assets/images/app-icons/quickbooks.svg`)" alt="" class="mr-6">
            </template>
            <template #cell(customers)="data">
                <b-avatar-group size="32px">
                    <b-avatar
                    :class="[data.item.collaborators.length<2?'mx-0':'']" v-for="(c,index) in data.item.collaborators.slice(0,1)" :key="index"
                    :text="c.email.slice(0,1).toUpperCase()"
                    :variant="colors[index]"
                    v-b-tooltip.hover.v-primary="c.email"
                  />
                  <b-avatar v-if="data.item.collaborators.length > 1" 
                    class="mx-0" 
                    :text="`+${data.item.collaborators.length-1}`"
                    variant="light-success"
                    id="tooltip-avatar"
                    v-b-tooltip.hover.v-primary="getcollaborators(data.item.collaborators.slice(1))"
                    />
                </b-avatar-group>
            </template>
            <template #cell(dueDate)="data">
                {{ getFormatedDate(data.item.dueDate,"DD MMM YYYY") }}
            </template>
            <template #cell(attachment)="data">
                <img :src="require('@/assets/images/app-icons/pdf.png')" alt="">
            </template>
            <template #cell(c_v)="data">
                <div class="" v-for="(c,i) in data.item.collaborators" :key="i">{{c.email}}</div><br>
            </template>
            <template #cell(amount)="data">
                {{ data.item.amount | currency }}
            </template>
            <template #cell(totalReceivedPayments)="data">
                {{ data.item.totalReceivedPayments | currency }}
            </template>
            <template #cell(projectName)="data">
                {{ data.item.project.name?data.item.project.name:'N/A' }}
            </template>
            <template #cell(pendingPayments)="data">
                {{ data.item.pendingPayments | currency }}
            </template>
            <template #cell(type)="data">
                <b-badge :variant="data.item.type=='sent'?'light-success':'light-primary'">
                    {{ toCapitalize(data.item.type) }}
                </b-badge>
            </template>
            <template #cell(quickbooks)="data" > 
                    <feather-icon
                        icon="UploadIcon"
                        size="18"
                        :class="[data.item.status!='paid'?'disableClick':'']"
                        v-b-tooltip.hover.v-primary="'Upload to Quickbooks'"
                        @click="uploadToQuickbooks(data.item)"
                    />
            </template>
            <template #cell(actions)="data">
                <div class="">
                    <b-dropdown
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        text="Block Level Dropdown Menu"
                        right
                        variant="none"
                        size="sm"
                        boundary="viewport"
                        no-caret
                        >
                        <template #button-content>
                            <feather-icon
                                icon="MoreVerticalIcon"
                                size="18"
                            />
                        </template>
                        <b-dropdown-item class="d-md-none" @click="viewInvoiceDetails(data.item)">View Invoice</b-dropdown-item>
                        <b-dropdown-item v-if="type!='sent' && data.item.status!='paid' && data.item.status!='refunded' && data.item.status!='processing_payment'" @click="payInvoice(data.item)">{{data.item.status=='sent'?'Pay Invoice':'Pay Remaining'}}</b-dropdown-item>
                        <b-dropdown-item v-if="data.item.status=='draft'" @click="deleteInvoice(data.item._id)">Delete</b-dropdown-item>
                        <b-dropdown-item @click="downloadInvoice(data.item._id)">Download</b-dropdown-item>
                    </b-dropdown>
                </div>     
            </template>
            <template #cell(status)="data">
                <b-badge :variant="data.item.status=='sent'?(type=='sent'?'light-success':'light-secondary'):data.item.status=='paid'?'light-primary':data.item.status=='draft'?'light-secondary':data.item.status=='processing_payment'?'light-warning': 'light-info'">
                    {{ data.item.status=='sent'?(type=='sent'?'Sent':'Pending'):toCapitalize(data.item.status) }}
                </b-badge>
            </template>
            <template>
            </template>
        </app-paginated-table>
        <pending-signatures ref="pendingsignature"/>
    </div>
</template>
<script>
import {BTabs,BTab,BCard,BTableLite, BDropdown, BDropdownItem, BBadge,BSidebar, BAvatarGroup, BAvatar, VBTooltip, BTooltip} from 'bootstrap-vue'
import AppPaginatedTable from '@/components/app-components/AppPaginatedTable.vue'
import AppTextInput from '@/components/app-components/AppTextInput.vue'
import AppSelectBox from '@/components/app-components/AppSelectBox.vue'
import AppConfirmModal from '@/components/app-components/AppConfirmModal.vue'

import PendingSignatures from '@/components/functional-components/other/invoice/PendingSignatures.vue'
import {get_invoices_list,save_to_backup, download_invoice, delete_invoice} from '@/apis/others/invoices'
import {upload_to_quickbooks, get_quickbooks_invoices} from '@/apis/others/quickbooks' 
import {getUserData} from '@/auth/utils'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'
export default{
    directives: {
        'b-tooltip': VBTooltip,
        Ripple
    },
    components:{
        BTabs,
        BTab,
        BCard,
        BTableLite,
        AppPaginatedTable,
        AppTextInput,
        BDropdown,
        BDropdownItem,
        BBadge,
        AppSelectBox,
        AppConfirmModal,
        BSidebar,
        BAvatarGroup, 
        BAvatar,
        PendingSignatures,
        BTooltip,
    },
    data(){
        return{
            tabs:3,
            search:'',
            status_filter:{
                active:false,
                disabled:false  
            },
            fields:[
                {
                    key:'invoiceNo',
                    label:'Invoice #',
                    sortable:true
                },
                
                // {
                //     key:'description',
                //     label:'Description'
                // },
                {
                    key:'projectName',
                    label:'Project Name',
                    sortable:true
                },
                {
                    key:'amount',
                    label:'Amount'
                },

                {
                    key:'totalReceivedPayments',
                    label:'Amount Deposited',
                    sortable:true
                },
                {
                    key:'pendingPayments',
                    label:'Balance Due',
                    sortable:true
                },
                {
                    key:'status',
                    label:'Status',
                    sortable:true
                },
                {
                    key:'customers',
                    label:'Customers',
                },
                {
                    key:'dueDate',
                    label:'Due Date',
                    sortable:true
                },
                {
                    key:'attachment',
                    label:'Attachment'
                },
                {
                    key:'quickbooks',
                },
                {
                    key:'actions'
                }
            ],
            items: [],
            filters:{
                page:1,
                limit:10,
                search:'',
                status:''
            },
            type:this.getUserRole()=='client'?'received':'sent',
            current_page:1,
            total_records:0,
            total_pages:0,
            status:'',
            loading:false,
            delete_id:'',
            options: [
                { value: '', text: 'Status' },
                { value: 'pending', text: 'Pending' },
                { value: 'sent', text: 'Sent' },
                { value: 'partially_paid', text: 'Partially Paid'},
                { value: 'paid', text: 'Paid'},
                { value: 'refunded', text: 'Refunded'},
            ],
            showSidebar:false,
            invoicePaymentDetails:{
                invoiceId:this.$route.params.id,
                invoiceAmountPercentage:25,
                paymentType:'initial'
            },
            colors:['light-primary','light-info','light-success','light-danger','light-secondary'],
            loadMore:false,
            quickbooksFields:[
                {
                    key:'invoiceNo',
                    label:'Invoice #',
                    sortable:true
                },
                {
                    key:'description',
                    label:'Description'
                },
                {
                    key:'amount',
                    label:'Amount'
                },
                {
                    key:'type',
                    label:'Type'
                },
                {
                    key:'c_v',
                    label:'Customers/ Vendors',
                },
                {
                    key:'dueDate',
                    label:'Due Date',
                    sortable:true
                },
            ],
            quickBookInvoices:[],
            disableRowClick:false,
            currentTab:0
        }
    },
    mounted(){
        this.getInvoices()
    },
    computed:{
        getCurrentFields(){
            const currentFields = this.getUserRole()=='client'?this.fields.filter(f=>!['pendingPayments','totalReceivedPayments','projectName'].includes(f.key)):this.fields
            const Fields=!this.getQuickbook()?currentFields.filter(f=>f.key!='quickbooks'):currentFields
            return Fields
        },
        getFields(){
            return this.currentTab ==1?this.quickbooksFields:this.getCurrentFields
        },
        getItems(){
            return this.currentTab ==1?this.quickBookInvoices:this.items
        }
    },
    methods:{
        onTabChange(tab){
            this.currentTab=tab;
            if(tab==1){
                this.getQuickbookInvoices()
            }else{
                this.type=this.getUserRole()=='client'?'received':'sent'
                this.filters.page=1;
                this.filters.search=''
                this.filters.status=''
                this.getInvoices();
            }
   
        },
        onPageChange(page){
            this.filters.page=page
            this.getInvoices()
        },
        getInvoices(){
            this.loading=true;
            get_invoices_list(this.type,this.filters).then((resp)=>{
                this.loading=false;
                this.items=this.loadMore?[...this.items,...this.setInvoicesData(resp.data.invoices)]:this.setInvoicesData(resp.data.invoices);
                this.current_page=Number(resp.data.currentPage)
                this.total_records=resp.data.invoicesCount
                this.total_pages=resp.data.invoicesCount>0?resp.data.invoicesCount/this.filters.limit:0
                this.loadMore=false
            })
        },
        setInvoicesData(invoices){
            let newData=invoices.map(i=>{
                return {
                    projectName:i.project.name?i.project.name:'N/A', 
                    attachment:'',
                    ...i
                }
            })
            console.log("setInvoicesData: ",newData)
            return newData
        },
        
        viewInvoiceDetails(details){
            if(!this.disableRowClick){
                this.$router.push(`/invoice/details/${details.invoiceNo}/${details._id}`)
            }
        },
        getFormatedDate(date,format){
            return moment(date).format(format)
        },
        showPendingSignatures(pending_signatures, signed_by){
           let pending_sign=pending_signatures.map(s=>{
               return {
                   id:s._id,
                   name:`${s.first_name} ${s.last_name}`,
                   email:s.email,
                   signed:false
               }
           })
           let signed=signed_by.map(s=>{
               return {
                   id:s._id,
                   name:`${s.first_name} ${s.last_name}`,
                   email:s.email,
                   signed:true
               }
           })
           this.$refs.pendingsignature.showModal([...signed, ...pending_sign])
        },
        saveToBackup(id){
            this.backupLoading=true;
             save_to_backup(id).then(resp=>{
                this.backupLoading=false;
                this.$bvToast.toast(resp.message, {
                        title: 'Saved to backup',
                        variant: 'success',
                        solid: true,
                    })
             }).catch(error => {
                this.$bvToast.toast(error.message, {
                        title: error.error,
                        variant: 'danger',
                        solid: true,
                    })
                    this.backupLoading=false;   
             })
        },
        downloadInvoice(id){
            this.downloadLoading=true;
            download_invoice(id).then(resp=>{
                this.downloadLoading=false;
                this.download(resp.url)
            })
        },
        download(url){
            var link = document.createElement("a");
            // If you don't know the name or want to use
            // the webserver default set name = ''

            link.setAttribute('download', this.$route.params.invoice);
            link.href = url;
            document.body.appendChild(link);
            link.click();
            link.remove();
        },
        payInvoice(data){
            this.$router.push({path:`/invoice/pay/${data.invoiceNo}`, query:{invoice_id:data._id,amount:data.amount, type:data.status=='sent'&& data.includesInitialDeposit?'initial':data.status=='partially_paid'&& data.includesInitialDeposit?'remaining':'complete'}})
        },
       
        toCapitalize(name){
            let words = name.split("_")
            let upperCaseStr= words.map(w=>`${w.charAt(0).toUpperCase()}${w.slice(1)}`).join(" ")
            return upperCaseStr
        },
        getcollaborators(collaborators){
            let colab=''
            collaborators.map(c=>{
                colab=`${colab}${c.email}\n`
            });
            return colab
        },
        uploadToQuickbooks(data){
            this.disableRowClick=true

            upload_to_quickbooks({invoiceId:data._id,type:this.type}).then(resp=>{
                this.$bvToast.toast(resp.message, {
                        title: 'Uploaded to quickbooks',
                        variant: 'success',
                        solid: true,
                    })
                this.disableRowClick=false;
                
            }).catch(err=>{
                this.disableRowClick=false;
                this.$bvToast.toast(err.message, {
                        title: err.error,
                        variant: 'danger',
                        solid: true,
                    })
                
            })
        },
        getQuickbookInvoices(){
            this.quickBookInvoices=[]
            this.loading=true;
            get_quickbooks_invoices(this.filters).then(resp=>{
                this.loading=false;
                this.quickBookInvoices=this.loadMore?[...this.quickBookInvoices,...resp.data.quickbooksInvoices]:resp.data.quickbooksInvoices;
                this.current_page=Number(resp.data.page)
                this.total_records=resp.data.quickbooksInvoicesCount
                this.total_pages=resp.data.quickbooksInvoicesCount>0?resp.data.quickbooksInvoicesCount/this.filters.limit:0
                this.loadMore=false
            }).catch(err=>{
                this.loading=false;
                console.log('Quickbook error: ',err)
            })
        },
        loadMoreItems(){
            this.loadMore=true;
            this.filters.page+=1
            this.getInvoices()
        },
        getQuickbook(){
            const user=getUserData()
            return user.quickbooksAvailable
        },
        getUserRole(){
            const user=getUserData()
            return user.role
        },
        deleteInvoice(id){
            delete_invoice(id).then((resp)=>{
                this.$bvToast.toast(resp.message, {
                    title: 'Invoice Deleted',
                    variant: 'success',
                    solid: true,
                })
                this.getInvoices()
            }).catch(error => {
                this.$bvToast.toast(error.message, {
                        title: error.error,
                        variant: 'danger',
                        solid: true,
                    }) 
             })
            
        }
    }
}
</script>
<style scoped>
.dropdown-postion{
    position:absolute;
    top: 45px;
}
.disableClick{
    cursor: default;
    pointer-events: none;
    opacity: .5;
}
.nav{
    flex-wrap: nowrap !important;
    overflow-x:auto !important;
}
</style>